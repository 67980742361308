import {
	EndpointMapping,
	EndpointMappings,
	endpointMappings,
	entityUserRoleEndpointMappings,
	groupEndpointMapping
} from 'studiokit-scaffolding-js'

const groups: EndpointMapping = {
	...groupEndpointMapping,
	groupAssignments: {
		_config: {
			isCollection: true
		}
	}
}

const groupAssignments: EndpointMapping = {
	_config: {
		isCollection: true
	},
	evaluations: {
		_config: {}
	},
	scores: {
		_config: {
			isCollection: true
		}
	},
	// create a separate key for csv download, since the result is not a collection
	scoresCsv: {
		_config: {
			fetch: {
				path: 'scores'
			}
		}
	},
	scoreStats: {
		_config: {}
	},
	calibrations: {
		_config: {
			isCollection: true
		},
		artifacts: {
			_config: {
				isCollection: true
			},
			token: {
				_config: {}
			}
		},
		evaluations: {
			_config: {
				isCollection: true
			}
		}
	},
	submissions: {
		_config: {
			isCollection: true
		},
		artifacts: {
			_config: {
				isCollection: true
			},
			token: {
				_config: {}
			}
		},
		aesFeedback: {
			_config: {
				isCollection: true
			},
			rubricCriterionFeedback: {
				_config: {
					isCollection: true
				}
			}
		},
		evaluations: {
			_config: {
				isCollection: true
			}
		}
	},
	learnerStatuses: {
		_config: {
			isCollection: true
		}
	},
	learnerEvaluations: {
		_config: {
			isCollection: true
		}
	},
	activationStatus: {
		_config: {}
	},
	jobLogs: {
		_config: {
			isCollection: true
		}
	},
	distribute: {
		_config: {
			fetch: {
				method: 'POST'
			}
		}
	},
	score: {
		_config: {
			fetch: {
				method: 'POST'
			}
		}
	},
	pushGrades: {
		_config: {
			fetch: {
				method: 'POST'
			}
		}
	},
	lateActivityPermits: {
		_config: {
			isCollection: true
		}
	},
	finalizeSuperiorEvaluations: {
		_config: {
			fetch: {
				method: 'POST'
			}
		}
	}
}

const rubrics: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/rubrics'
		}
	},
	...entityUserRoleEndpointMappings('rubricUserRoles')
}

const assignments: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/assignments'
		}
	},
	artifacts: {
		_config: {
			isCollection: true
		},
		token: {
			_config: {}
		}
	},
	artifactConstraints: {
		_config: {
			isCollection: true
		}
	},
	calibrations: {
		_config: {
			isCollection: true
		},
		artifacts: {
			_config: {
				isCollection: true
			},
			token: {
				_config: {}
			}
		},
		evaluations: {
			_config: {
				isCollection: true
			}
		}
	},
	activationStatus: {
		_config: {}
	},
	...entityUserRoleEndpointMappings('assignmentUserRoles')
}

const appEndpointMappings: EndpointMappings = {
	...endpointMappings,
	userSettings: {
		_config: {
			isCollection: true
		}
	},
	dashboard: {
		_config: {
			fetch: {
				path: '/api/dashboard'
			}
		}
		// assignments: groupAssignments
	},
	submissions: {
		_config: {
			isCollection: true
		},
		evaluations: {
			_config: {
				isCollection: true
			}
		}
	},
	groups,
	assignments,
	groupAssignments,
	rubrics,
	aesFeedbackArtifactTokens: {
		_config: {
			fetch: {
				path: '/api/aesFeedbackArtifactTokens/{:entityId}'
			}
		}
	},
	search: {
		_config: {
			fetch: {
				path: ''
			}
		},
		users: {
			_config: {
				isCollection: true,
				fetch: {
					path: '/api/users'
				}
			}
		},
		groups,
		assignments,
		groupAssignments: {
			_config: {
				isCollection: true,
				fetch: {
					path: '/api/groupAssignments'
				}
			},
			jobLogs: {
				_config: {
					isCollection: true
				}
			}
		},
		rubrics
	}
}

export default appEndpointMappings
