import * as Sentry from '@sentry/react'
import { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { ErrorBoundary } from 'studiokit-scaffolding-js/lib/components/ErrorBoundary'
import { Loading } from 'studiokit-scaffolding-js/lib/components/Loading'
import NewVersionAlert from 'studiokit-scaffolding-js/lib/components/NewVersionAlert'
import Notifications from 'studiokit-scaffolding-js/lib/components/Notifications'
import { isInsideIFrame } from 'studiokit-scaffolding-js/lib/utils/lti'
import CONFIG from '../configuration'
import { ReduxState } from '../types'
import { Footer } from './Footer'
import Header from './Header'
import Routes from './Routes'

export interface AppProps {
	isAppInitialized: boolean
}

export class App extends Component<AppProps & RouteComponentProps> {
	render() {
		const { isAppInitialized, location } = this.props
		const isLtiPage = location.pathname.includes('lti-launch')
		return (
			<>
				<Helmet
					defaultTitle="Circuit"
					titleTemplate="Circuit - %s"
					meta={[
						{ name: 'description', content: 'Circuit - Purdue' },
						{ property: 'og:type', content: 'article' }
					]}
					link={[]}
				/>
				<a className="skip-main" href="#main">
					Skip to content
				</a>
				<Header isDecorative={isLtiPage} />
				<ErrorBoundary>
					<NewVersionAlert />
					<main id="main" role="main">
						{!isAppInitialized ? <Loading /> : <Routes />}
					</main>
					<Notifications />
				</ErrorBoundary>
				{
					/* Don't show footer when inside LTI Launch iFrame */
					(!isLtiPage || !isInsideIFrame()) && <Footer />
				}
			</>
		)
	}
}

const mapStateToProps = (state: ReduxState) => {
	return {
		isAppInitialized: CONFIG.IS_DOWNTIME || state.auth.isInitialized
	}
}

const WrappedApp = connect(mapStateToProps)(Sentry.withProfiler(App))
export default WrappedApp
