import { SagaIterator } from 'redux-saga'
import { dispatchModelFetchRequest } from 'studiokit-scaffolding-js/lib/redux/actionCreator'

// eslint-disable-next-line require-yield
export function* onPostLoginSaga(): SagaIterator<boolean> {
	// load assignments, and rubrics on initial login. refreshing happens elsewhere
	dispatchModelFetchRequest({ modelName: 'assignments' })
	dispatchModelFetchRequest({ modelName: 'rubrics' })
	return true
}
