import IconGrid from '@material-ui/icons/Apps'
import IconAssignmentInd from '@material-ui/icons/AssignmentInd'
import IconGroup from '@material-ui/icons/Group'
import IconFile from '@material-ui/icons/InsertDriveFile'
import IconSchool from '@material-ui/icons/School'
import { FunctionComponent } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { BASE_ACTIVITY, UserInfo } from 'studiokit-scaffolding-js'
import GroupsDropdown from 'studiokit-scaffolding-js/lib/components/Dropdowns/GroupsDropdown'
import { ManagedNavDropdown } from 'studiokit-scaffolding-js/lib/components/Dropdowns/ManagedNavDropdown'
import {
	canPerformActivityGlobally,
	canPerformActivityGloballyOrOnSomeEntities
} from 'studiokit-scaffolding-js/lib/utils/baseActivity'
import { groupsAsAnythingButLearner } from 'studiokit-scaffolding-js/lib/utils/groupRoles'
import { ACTIVITY } from '../../constants/activity'
import { ReduxState } from '../../types'
import { UserDropdown } from './Dropdowns/UserDropdown'
import { Logo } from './Logo'

import './index.css'

export interface HeaderProps extends RouteComponentProps {
	isAuthenticated: boolean
	isDecorative?: boolean
	currentUser?: UserInfo
	canCreateGroups: boolean
	canReadGroupAsAnythingButLearner: boolean
	canReadAnyUserRole: boolean
	canReadAnyUser: boolean
	canCreateAssignments: boolean
	canReadAssignment: boolean
	canCreateRubrics: boolean
	canReadRubric: boolean
}

export const Header: FunctionComponent<HeaderProps> = ({
	isAuthenticated,
	isDecorative,
	currentUser,
	canCreateGroups,
	canReadGroupAsAnythingButLearner,
	canReadAnyUserRole,
	canReadAnyUser,
	canCreateAssignments,
	canReadAssignment,
	canCreateRubrics,
	canReadRubric
}) => {
	let authenticatedMenu = null

	if (isAuthenticated && !isDecorative && !!currentUser && !!currentUser.id) {
		authenticatedMenu = (
			<Navbar.Collapse id="navbar-collapsable ma0 pa0">
				<Nav role="navigation" className="ml-auto">
					<LinkContainer to="/" exact>
						<Nav.Link>Home</Nav.Link>
					</LinkContainer>
					<GroupsDropdown title="Courses I’m Teaching" id="groupsTeaching" anyRoleButLearner />
					<GroupsDropdown title="Courses I’m Taking" id="groupsTaking" />
					{(canCreateGroups ||
						canCreateAssignments ||
						canCreateRubrics ||
						canReadAnyUserRole ||
						canReadAnyUser ||
						canReadGroupAsAnythingButLearner ||
						canReadAssignment ||
						canReadRubric) && (
						<ManagedNavDropdown id="admin-menu" title="Manage">
							{canReadAnyUserRole && (
								<LinkContainer to="/admins" exact>
									<NavDropdown.Item id="manageAdminsDropdownItem">
										<IconAssignmentInd className="mr2 icon-size-2 fill-white pb1" />
										Admins
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{canReadAnyUser && (
								<LinkContainer to="/users" exact>
									<NavDropdown.Item id="manageUsersDropdownItem">
										<IconGroup className="mr2 icon-size-2 fill-white pb1" />
										Users
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canReadGroupAsAnythingButLearner || canCreateGroups) && (
								<LinkContainer to="/courses" exact>
									<NavDropdown.Item id="manageCoursesDropdownItem">
										<IconSchool className="mr2 icon-size-2 fill-white pb1" />
										Courses
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canCreateAssignments || canReadAssignment) && (
								<LinkContainer to="/assignments" exact>
									<NavDropdown.Item id="manageAssignmentsDropdownItem">
										<IconFile className="mr2 icon-size-2 fill-white pb1" />
										Assignments
									</NavDropdown.Item>
								</LinkContainer>
							)}
							{(canCreateRubrics || canReadRubric) && (
								<LinkContainer to="/rubrics" exact>
									<NavDropdown.Item id="manageRubricsDropdownItem">
										<IconGrid className="mr2 icon-size-2 fill-white pb1" />
										Rubrics
									</NavDropdown.Item>
								</LinkContainer>
							)}
						</ManagedNavDropdown>
					)}
					<UserDropdown userInfo={currentUser} />
				</Nav>
			</Navbar.Collapse>
		)
	}

	return (
		<Navbar bg="dark" collapseOnSelect variant="dark" className="navbar-overall" expand="md">
			<div className="navbar-header-wrapper">
				<Navbar.Brand className="pa0 ma0">
					<Logo useLink={!isDecorative} />
				</Navbar.Brand>
				{!!authenticatedMenu && <Navbar.Toggle aria-controls="navbar-collapsable" />}
				{authenticatedMenu}
			</div>
		</Navbar>
	)
}

const mapStateToProps = (state: ReduxState) => {
	const isAuthenticated = state.auth && state.auth.isAuthenticated
	const userInfo = state.models.user ? state.models.user.userInfo : undefined
	return {
		isAuthenticated: isAuthenticated,
		currentUser: isAuthenticated ? userInfo : undefined,
		canReadAnyUserRole: canPerformActivityGlobally(BASE_ACTIVITY.USER_ROLE_READ_ANY, { userInfo }),
		canReadAnyUser: canPerformActivityGlobally(BASE_ACTIVITY.USER_READ_ANY, { userInfo }),
		canCreateGroups: canPerformActivityGlobally(BASE_ACTIVITY.GROUP_CREATE, { userInfo }),
		canReadGroupAsAnythingButLearner:
			canPerformActivityGlobally(BASE_ACTIVITY.GROUP_READ, { userInfo }) ||
			(!!state.models.groups && groupsAsAnythingButLearner(state.models.groups).length > 0),
		canCreateAssignments: canPerformActivityGlobally(ACTIVITY.ASSIGNMENT_CREATE, { userInfo }),
		canReadAssignment:
			!!state.models.assignments &&
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.ASSIGNMENT_READ, {
				userInfo,
				entities: state.models.assignments
			}),
		canCreateRubrics: canPerformActivityGlobally(ACTIVITY.RUBRIC_CREATE, { userInfo }),
		canReadRubric:
			!!state.models.rubrics &&
			canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.RUBRIC_READ, {
				userInfo,
				entities: state.models.rubrics
			})
	}
}

const WrappedHeader = withRouter(connect(mapStateToProps)(Header))
export default WrappedHeader
